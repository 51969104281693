<template>
  <div>
    <div class="sidebar_nav_section" style="width: 10rem; overflow: auto" :style="{ height: height + 'px' }">
      <div>
        <!--   This section should be at the top -->
        <div class="section_nav_section">
          <h6 class="text-center sidebar_heading">Reports</h6>
          <ul v-if="true" class="left_mnemo h-full coreMenu">
            <li>
              <div class="color flex w-full items-center mt-1 fontSize" v-for="(report, index) in reportsList"
                :key="index" :title="report">
                <button :for="index" class="runLables uppercase w-full overflow-ellipsis overflow-hidden"
                  :class="selectedReport == report ? 'active' : ''" @click="selectingReport(report, 'selectedReport')">
                  {{ report }}
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Reports-sidebar",
  data() {
    return {
      reportsList: ["MSE", "D-EXPONENT"],
    };
  },
  props: {
    displayId: String,
    widgetId: String,
    widgetIndex: [Number, String],
    displayIndex: [Number, String],
    idx: [Number],
    height: [Number, String],
  },
  components: {},
  async mounted() { },
  methods: {
    async commonDispatcher(val, key) {

      await this.$store.dispatch("rect/swabSurgeRealTimedata", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: val,
        field: key,
      });

    },
    async selectingReport(report, key) {
      await this.commonDispatcher(report, key);
      console.log('report',report,report == 'D-EXPONENT')
      if (report == 'D-EXPONENT') {
        console.log('coming to before mount',this.$store.state.rect.rects[this.idx])
        console.log(Object.keys(this.$store.state.rect.rects[this.idx]).includes('DExponent'))
        if (Object.keys(this.$store.state.rect.rects[this.idx]).includes('DExponent')) {
          console.log("alredy key existed",this.$store.state.rect.rects[this.idx])
        } else {
          const object = {
            axisLabelColor: '',
            graphScaleColor: '#ff0000',
            gridColor: '',
            graphBackgroundColor: '',
            xAxisLabelSize: '',
            yAxisLabelSize: '',
            secondaryAxisLabelColor: '',
            graphSecondaryScaleColor: '',
            yAxisSecondaryLabelSize: '',
            primaryYaxisFullName: { name: "depth", value: "dmea" },
            primaryYaxisUnit: '',
            secondaryYaxisFullName: { name: "", value: "" },
            secondryYaxisUnit: '',
            isSecondaryAxis: false,
            isSlider:false,
            primaryXaxisFullName : { name: "D-Exponent", value: "dexponent" },
            scale : 1,
            showTable : false,
            selectedUnit : 'in'
          }
          await this.commonDispatcher(object,'DExponent')
        }
      }
      if (report == 'MSE') {
        console.log('coming to before mount',this.$store.state.rect.rects[this.idx])
        console.log(Object.keys(this.$store.state.rect.rects[this.idx]).includes('MSE'))
        if (Object.keys(this.$store.state.rect.rects[this.idx]).includes('MSE')) {
          console.log("alredy key existed",this.$store.state.rect.rects[this.idx])
        } else {
          const object = {
            axisLabelColor: '',
            graphScaleColor: '#00f2ff',
            gridColor: '',
            graphBackgroundColor: '',
            xAxisLabelSize: '',
            yAxisLabelSize: '',
            sevondaryAxisLabelColor: '',
            graphSecondaryScaleColor: '#ff0000',
            yAxisSecondaryLabelSize: '',
            primaryYaxisFullName: { name: "MSE", value: "msetotal" },
            primaryYaxisUnit: 'psi',
            secondaryYaxisFullName: { name: "", value: "" },
            secondryYaxisUnit: '',
            isSecondaryAxis: false,
            isSlider:false,
            primaryXaxisFullName :{ name: "", value: "" },
            scale : 1,
            secondaryYaxisUnitFactor : '',
            primaryYaxisUnitFactor : '',
            showTable : false
          }
          await this.commonDispatcher(object,'MSE')
        }
      }
      console.log(this.$store.state.rect.rects, 'from if outside')
    },
  },
  watch: {},
  computed: {
    darkDark() {
      return this.$store.state.rect.darkmode;
    },
    selectedReport() {
      if (
        Object.keys(this.$store.state.rect.rects[this.idx]).includes(
          "selectedReport"
        )
      ) {

        return this.$store.state.rect.rects[this.idx].selectedReport;
      } else {
        return null;
      }
    },
  },
};
</script>
<style scoped>
.color {
  color: var(--colorBoxHeadingText);
}

.fontSize {
  font-weight: 600;
  font-size: calc(0.2vw + 0.2vh + 0.65vmin) !important;
}

.section_nav_section {
  padding: 0px;
}

.runLables {
  text-align: left;
  height: 30px;
  padding: 0px 10px;
  background: var(--sidebarListBgColor);
}

.active {
  color: var(--activeTextColor);
}

.sidebar_heading {
  width: 100%;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  color: var(--headingColor);
  padding: 0px;
  letter-spacing: normal;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* text-decoration: underline var(--headingColor) 2px; */
}
</style>