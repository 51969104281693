<template>
  <div class="card" :class="darkDark !== 'white' ? 'darkCard' : 'card'">
    <!-- <div class="card-header" v-if="title">{{ title }}</div> -->
    <div class="card-body flex flex-row justify-start">
      <p class="card-title"></p>
      <div id="dataTables-example_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
        <div class="row">
          <div class="col-sm-12">
            <table class="border-collapse border border-green-800 w-full table-auto" id="dataTables-example"
              :style="{ color, overflowX: 'hidden' }">
              <thead class="bg-gray-200 text-gray-600 text-sm leading-normal" :style="{
    color:
      headColor || (this.darkDark !== 'white' ? '#cccccc' : '#000000'),
    // background: headBackground || (this.darkDark? '#B0B0B0': '#111111'),
    height: '50px',
  }">
                <tr class="py-5">
                  <th :class="'text-center'" v-for="(col, index) in columns" :key="index" :style="{
    textAlign: 'center',
    width: '100%',
    position: 'sticky',
    top: '-1px',
    transition: 'var(--transition)',
    background:
      headBackground ||
      (this.darkDark !== 'white'
        ? 'var(--nav1BgClr)'
        : '#B0B0B0'),
  }">
                    <div v-if="col.label.split(' ')[1] == 'holedia' || index == 6" class="custom-select-table">
                      <div class="selected" style="line-height: 11px">
                        <span class="uppercase" :style="{ fontSize: `${headFontSize}px` }">{{ col.label.split(" ")[0]
                          }}</span>
                        <br />
                        <div class="flex items-center dropdown-div">
                          <select v-model="selectedUnit" @change="onchangeUnit(selectedUnit, this.copyData)"
                            class="dropdown" :style="{ fontSize: `${headFontSize - 1}px` }">
                            <option class="option" :style="{ fontSize: `${headFontSize - 1}px` }"
                              v-for="unit, index of units" :value="unit">{{ unit }}</option>
                          </select>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-chevron-down dropdown-icon" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
                          </svg>
                        
                        </div>

                      </div>
                    </div>
                    <div v-else class="custom-select-table">
                      <div class="selected" style="line-height: 11px">
                        <span class="uppercase p-2" :style="{ fontSize: `${headFontSize}px` }">{{ col.label.split(" ")[0]
                          }}</span>
                        <br />
                        <span v-if="col.label.split(' ')[1] != 'undefined'">({{ col.label.split(" ")[1] }})
                        </span>
                        <br />
                      </div>
                    </div>
                  </th>
                  <th v-if="rows.length > 0" :class="''" :style="{

    width: '100%',
    position: 'sticky',
    top : '-1px',
    transition: 'var(--transition)',
    background:
      headBackground ||
      (this.darkDark !== 'white'
        ? 'var(--nav1BgClr)'
        : '#B0B0B0'),

  }">
                    <div class="action-container">

                      <span class="uppercase" :style="{ fontSize: `${headFontSize}px` }">ACTION
                      </span>
                    </div>

                  </th>
                </tr>
              </thead>
              <tbody v-if="rows.length > 0" class="text-black text-sm">
                <tr class="border-gray-200 mb-2" :style="{
    color:
      color || (this.darkDark !== 'white' ? '#fff' : ''),
    backgroundColor: 'var(--root_nav)',
    transition: 'var(--transition)',
    width: '100%',
    position: 'sticky',
    top: '50px', /* Adjust the top value to match the height of the sticky header */
    zIndex: '0'
  }">
                  <td v-for="(col, j) in columns" :key="j"
                    class="text-center overflow-hidden whitespace-nowrap auto-font py-1.5">
                    <input type="number" style="background: inherit; color: inherit;margin-left: 30%;"
                      :placeholder="col.label.split(' ')[0]" v-model="inputValues[j]" />

                  </td>
                  <td>
                    <button @click="onclickAddButton"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                        fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                        <path
                          d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                      </svg></button>
                    <button @click="removestickyrowvalues" class="ml-3">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-trash3" viewBox="0 0 16 16">
                        <path
                          d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                      </svg>
                    </button>
                  </td>
                </tr>
                <tr :class="{ 'highlight-row': row.isnew }" v-for="(row, i) in copyData" :key="i" :style="i % 2 == 0
    ? {
      color:
        color || (this.darkDark !== 'white' ? '#fff' : ''),
      backgroundColor:
        background ||
        (this.darkDark !== 'white'
          ? 'var(--navBar2Bg)'
          : ''),
      transition: 'var(--transition)',
    }
    : {
      color:
        color || (this.darkDark !== 'white' ? '#fff' : ''),
      backgroundColor: 'var(--root_nav)',
      transition: 'var(--transition)',
    }
    ">
                  <td class="text-center overflow-hidden whitespace-nowrap auto-font py-1.5 " :style="{
    // fontSize: `${headFontSize}px`,
    color:
      parseInt(row[col.field]) >=
        parseInt(
          $store.state.rect.rects[widgetPropertyIndex][
          col.label.split(' ').join('').toLowerCase() +
          'StartRange'
          ]
        ) &&
        parseInt(row[col.field]) <=
        parseInt(
          $store.state.rect.rects[widgetPropertyIndex][
          col.label.split(' ').join('').toLowerCase() +
          'EndRange'
          ]
        )
        ? alertColor || 'red'
        : color || (this.darkDark !== 'white' ? '#fff' : ''),
    // height: `${height * 0.1}px`,
    textAlign: 'center',
  }" v-for="(col, j) in columns" :key="j" v-bind:j="j">
                    <div v-if="col.display" v-html="col.display(row)"></div>
                    <input type="number" style="background: inherit; color: inherit; margin-left: 30%;width: 100%;"
                      :value="row[col.field] ? Math.floor(parseFloat(row[col.field] * 100)) / 100 : 0.00"
                      @change="updateRowValue(i, col.field, $event.target.value)" data-toggle="tooltip"
                      :disabled="['bitSize', 'mudmotorrpm', 'totalrpm', 'dexponent'].includes(col.field)"
                      :title="['bitSize', 'mudmotorrpm', 'totalrpm', 'dexponent'].includes(col.field) ? 'this field is disabled' : ''" />

                    <!-- <span v-else>{{
                      row[col.field]
                        ? parseFloat(row[col.field]).toFixed(1)
                        : ""
                    }}</span> -->
                  </td>
                  <td>
                    <button @click="removerow(i)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-trash3" viewBox="0 0 16 16">
                        <path
                          d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                      </svg>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
        <div class="row" v-if="rows.length > 0" :style="{ width: `${width}%` }"></div>
        <div class="row" v-else>
          <div :style="{
    color: this.darkDark !== 'white' ? '#cccccc' : 'var(--textColor)',
  }" class="col-sm-12 text-center">
            <span class="nodata-msg"> {{ messages.noDataAvailable }}</span>

          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
String.prototype.format = function () {
  var args = arguments;
  return this.replace(/{([0-9]*)}/g, function (match, number) {
    return typeof args[number] != "undefined" ? args[number] : match;
  });
};
import {
  defineComponent,
  ref,
  reactive,
  computed,
  watch,
  onBeforeUpdate,
  nextTick,
} from "vue";
import helperServices from "../../../../helper-services";
import { db } from '../../../../db'

export default defineComponent({
  name: "my-table",
  props: {
    wellId: String,
    alertColor: {
      type: String,
    },
    widgetPropertyIndex: {
      type: String,
    },
    tableId: {
      type: String,
    },
    color: {
      type: String,
    },
    background: {
      type: String,
    },
    height: {
      type: String,
    },
    width: {
      type: String,
    },
    headColor: {
      type: String,
    },
    headBackground: {
      type: String,
    },
    headFontSize: {
      type: String,
      default: "13",
    },
    isLoading: {
      type: Boolean,
      require: true,
    },
    isReSearch: {
      type: Boolean,
      require: true,
    },
    hasCheckbox: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    columns: {
      type: Array,
      default: () => {
        return [];
      },
    },
    rows: {
      type: Array,
      default: () => {
        return [];
      },
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 100,
    },
    page: {
      type: Number,
      default: 1,
    },
    sortable: {
      type: Object,
      default: () => {
        return {
          order: "id",
          sort: "asc",
        };
      },
    },
    bitsizeCategory: String,
    tableConfiguration: Object,
    displayId: String,
    widgetId: String,
    messages: {
      type: Object,
      default: () => {
        return {
          pagingInfo: "Showing {0}-{1} of {2}",
          pageSizeChangeLabel: "Row count:",
          gotoPageLabel: "Go to page:",
          noDataAvailable: "No data",
        };
      },
    },
  },
  data() {
    return {
      dropdownPopoverShow: false,
      backgroundColor: this.background,
      inputValues: [],
      initialRow: {
        "dmea": null,
        "rop": null,
        "wob": null,
        "mfi": null,
        "bitsize": null,
        "rpm": null,
        "mudmotorrpm": null,
        "totalrpm": null,
        "mud density": null,
        "dexponent": null
      },
      isWhiteTheme: false,
      units: [],
      units_category_id: null,
      factor: 1,
      copyData: [],
      selectedUnit: null,
      isDropdownOpen: false
    };
  },
  computed: {
    darkDark() {
      console.log("table dark dark : ", this.$store.state.rect.darkmode);
      return this.$store.state.rect.darkmode;
    },
  },
  emits: ['onclickadd', "delete", "edit"],
  methods: {

    async commonDispatcher(keyToDispatch, val) {
      setTimeout(() => {
        this.$store.dispatch("rect/swabSurgeRealTimedata", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          value: val,
          field: keyToDispatch,
        });
      }, 300);
    },
    async onchangeUnit(selectedUnit, list1) {
      let list = JSON.parse(JSON.stringify(list1))
      this.isDropdownOpen = true
      const object = this.$store.state.rect.rects[this.widgetPropertyIndex].DExponent;
      console.log(object, 'object45', selectedUnit)


      await this.commonDispatcher("DExponent", {
        ...object,
        "selectedUnit": selectedUnit

      });
      if (selectedUnit == 'in') {
        // this.copyData = JSON.parse(JSON.stringify(this.rows))
        for (var i = 0; i < list.length; i++) {
          if (!list[i].isnew) {
            list[i].bitSize = parseFloat(this.rows[i].bitSize)
          }
        }
        this.copyData = list
        this.factor = 1
        console.log(this.copyData, 'copydata in selectedunit')


      } else {

        console.log(selectedUnit, 'selected unit')
        console.time("coming to rows")
        await db.unitfactors
          .where("units_category_id")
          .equals(this.units_category_id || 1)
          .each((fact) => {
            if (
              fact.from_unit == 'in' &&
              fact.to_unit == selectedUnit
            ) {
              this.factor = fact.conversion_factor

              console.log(
                "unit_conversion_factor_change-for metrics well::",
                fact, fact.conversion_factor
              );


            }
          });

        if (this.factor != 1) {

          for (var i = 0; i < list.length; i++) {
            if (!list[i].isnew) {

              console.log("converted values", list[i].bitSize, "original values", parseFloat(this.rows[i].bitSize), parseFloat(this.rows[i].bitSize) * this.factor, 'conversion')
              list[i].bitSize = parseFloat(this.rows[i].bitSize) * this.factor
            }
          }

        }
        this.copyData = list
        console.timeEnd("coming to rows")
      }



    },
    highlightParent(event) {
      event.target.closest('td').classList.add('focused');
    },
    removeHighlightParent(event) {
      event.target.closest('td').classList.remove('focused');
    },
    updateRowValue(rowindex, changedkey, chnagedValue) {
      this.copyData[rowindex][changedkey] = chnagedValue;
      console.log(this.copyData, 'updated row')

      console.log(this.copyData[rowindex], changedkey, chnagedValue, 'updatedvalue')
      const object = {
        ...this.copyData[rowindex], [changedkey]: chnagedValue ? parseFloat(chnagedValue) : '', bitSize: this.rows[rowindex].bitSize
      }
      console.log(object, 'updated object', this.rows[rowindex])
      this.$emit("edit", object)

    },
    removerow(index) {
      console.log(index, 'table')
      this.$emit('delete', index)

    },
    removestickyrowvalues() {
      this.inputValues = []
    },
    onclickAddButton() {
      this.$store.dispatch("data/startLoading");

      setTimeout(async () => {

        const inputObject = {};
        this.columns.forEach((col, index) => {
          inputObject[col.field] = this.inputValues[index] || ''; // Create key-value pairs in the object
        });
        let isValid = true

        const requiredKeys = ['rop', 'bitSize', 'dmea', 'mfi', 'mud density', 'rpm', 'wob'];
        for (const key of requiredKeys) {
          if (inputObject[key] == '') {
            isValid = false;
            break; // Exit the loop if any required field is empty
          }
        }

        if (isValid) {

          console.log(inputObject, 'valid');
          this.$toast.clear();
          this.$toast.success(
            `Added Successfully
            `,
            {
              duration: 2000,
              position: "top",
              pauseOnHover: true,
            }

          );
          inputObject['isnew'] = true
          this.$emit("onclickadd", inputObject)
          this.inputValues = []
        } else {
          console.log(inputObject, 'invalid');
          this.$toast.clear();
          this.$toast.error(
            `Please Enter The Required Fields
            `,
            {
              duration: 2000,
              position: "top",
              pauseOnHover: true,
            }
          );
        }
        this.$store.dispatch("data/stopLoading");
      }, 0)
      // Output the object with input values
    },
    setContextIdWidget(e) {
      this.$store.dispatch("disp/setContextId", {
        contextId: this.displayId,
        contextWidgetId: this.widgetId,
        contextType: "widget",
      });
      e.stopPropagation();
    },
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
    dark() {
      this.$emit("dark");
    },

    light() {
      this.$emit("light");
    },
  },
  watch: {
    async rows(val) {
      console.log('coming to rows')

      const selectedUnit = this.$store.state.rect.rects[this.widgetPropertyIndex].DExponent.selectedUnit
      this.selectedUnit = selectedUnit
      try {
        await this.onchangeUnit(selectedUnit, JSON.parse(JSON.stringify(val)))
        console.log(selectedUnit, 'selected unit in watch')

        console.log(this.copyData, 'after the execution onchnage')
      } catch (err) {
        console.log('error')
        this.copyData = JSON.parse(JSON.stringify(val))

      }


    }
  },

  async mounted() {

    console.log(this.copyData, this.rows, 'copydata in mounted')
    this.isWhiteTheme =
      this.$store.state.rect.darkmode == "white" ? true : false;

    let logDetails = await helperServices.getUnitCategories(this.bitsizeCategory);
    console.log(logDetails, 'logDetail455s')
    this.units = logDetails.units
    this.units_category_id = logDetails.units_category_id
    // const selectedUnit = this.$store.state.rect.rects[this.widgetPropertyIndex].DExponent.selectedUnit
    // await this.onchangeUnit(selectedUnit)
    // let all_mnemonics = logDetails.data.logs['depth'].mnemonics;
    // console.log(all_mnemonics,'all mnemonics')
    // color:  (this.$store.state.rect.rects[this.tableId][col.field+'StartRange'] > 100 && this.$store.state.rect.rects[id][col.field+'EndRange'] < 150) ? '#ff0000' :this.$store.state.rect.rects[id][col.field+'EndRange'] > 150 ? '#FF4500':row[col.field]>300?'#FF4500' :color,
    //
    //   "eeeeeeeeeeeeeeeeeeee",
    //   this.$store.state.rect.rects[this.tableId].table
    // );
  },
  setup(props, { emit }) {
    const setting = reactive({
      isCheckAll: false,
      keyColumn: computed(() => {
        let key = "";
        props.columns.forEach((col) => {
          if (col.isKey) {
            key = col.field;
          }
        });
        return key;
      }),
      page: props.page,
      pageSize: props.pageSize,
      maxPage: computed(() => {
        if (props.total <= 0) {
          return 0;
        }
        let maxPage = Math.floor(props.total / setting.pageSize);
        let mod = props.total % setting.pageSize;
        if (mod > 0) {
          maxPage++;
        }
        return maxPage;
      }),

      offset: computed(() => {
        return (setting.page - 1) * setting.pageSize + 1;
      }),

      limit: computed(() => {
        let limit = setting.page * setting.pageSize;
        return props.total >= limit ? limit : props.total;
      }),
      pagging: computed(() => {
        let startPage = setting.page - 2 <= 0 ? 1 : setting.page - 2;
        if (setting.maxPage - setting.page <= 2) {
          startPage = setting.maxPage - 4;
        }
        startPage = startPage <= 0 ? 1 : startPage;
        let pages = [];
        for (let i = startPage; i <= setting.maxPage; i++) {
          if (pages.length < 5) {
            pages.push(i);
          }
        }
        return pages;
      }),
    });

    const rowCheckbox = ref([]);
    if (props.hasCheckbox) {
      onBeforeUpdate(() => {
        rowCheckbox.value = [];
      });

      watch(
        () => setting.isCheckAll,
        (state) => {
          let isChecked = [];
          rowCheckbox.value.forEach((val) => {
            if (val) {
              val.checked = state;
              if (val.checked == true) {
                isChecked.push(val.value);
              }
            }
          });
          emit("return-checked-rows", isChecked);
        }
      );
    }

    const checked = () => {
      let isChecked = [];
      rowCheckbox.value.forEach((val) => {
        if (val && val.checked == true) {
          isChecked.push(val.value);
        }
      });
      emit("return-checked-rows", isChecked);
    };

    const clearChecked = () => {
      rowCheckbox.value.forEach((val) => {
        if (val && val.checked == true) {
          val.checked = false;
        }
      });
      emit("return-checked-rows", []);
    };

    const doSort = (order) => {
      let sort = "asc";
      if (order == props.sortable.order) {
        if (props.sortable.sort == "asc") {
          sort = "desc";
        }
      }
      let offset = (setting.page - 1) * setting.pageSize;
      let limit = setting.pageSize;
      emit("do-search", offset, limit, order, sort);

      if (setting.isCheckAll) {
        setting.isCheckAll = false;
      } else {
        if (props.hasCheckbox) {
          clearChecked();
        }
      }
    };

    /**
     * 切換頁碼
     *
     * @param number page     新頁碼
     * @param number prevPage 現在頁碼
     */
    const changePage = (page, prevPage) => {
      setting.isCheckAll = false;
      let order = props.sortable.order;
      let sort = props.sortable.sort;
      let offset = (page - 1) * setting.pageSize;
      let limit = setting.pageSize;
      if (!props.isReSearch || page > 1 || page == prevPage) {
        emit("do-search", offset, limit, order, sort);
      }
    };
    // 監聽頁碼切換
    watch(() => setting.page, changePage);

    /**
     * 切換顯示筆數
     */
    const changePageSize = () => {
      if (setting.page === 1) {
        changePage(setting.page, setting.page);
      } else {
        setting.page = 1;
        setting.isCheckAll = false;
      }
    };
    watch(() => setting.pageSize, changePageSize);

    const prevPage = () => {
      if (setting.page == 1) {
        return false;
      }
      setting.page--;
    };

    const movePage = (page) => {
      setting.page = page;
    };

    const nextPage = () => {
      if (setting.page >= setting.maxPage) {
        return false;
      }
      setting.page++;
    };

    watch(
      () => props.rows,
      () => {
        if (props.isReSearch) {
          setting.page = 1;
        }
        nextTick(function () {
          let localElement = document.getElementsByClassName("is-rows-el");
          emit("is-finished", localElement);
        });
      }
    );

    if (props.hasCheckbox) {
      return {
        setting,
        rowCheckbox,
        checked,
        doSort,
        prevPage,
        movePage,
        nextPage,
      };
    } else {
      return {
        setting,
        doSort,
        prevPage,
        movePage,
        nextPage,
      };
    }
  },
});
</script>

<style scoped>
.checkbox-th {
  width: 1%;
}

.both {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAAkElEQVQoz7X QMQ5AQBCF4dWQSJxC5wwax1Cq1e7BAdxD5SL+Tq/QCM1oNiJidwox0355mXnG/DrEtIQ6azioNZQxI0ykPhTQIwhCR+BmBYtlK7kLJYwWCcJA9M4qdrZrd8pPjZWPtOqdRQy320YSV17OatFC4euts6z39GYMKRPCTKY9UnPQ6P+GtMRfGtPnBCiqhAeJPmkqAAAAAElFTkSuQmCC");
}

.sortable {
  cursor: pointer;
  background-position: right;
  background-repeat: no-repeat;
  padding-right: 30px !important;
}

.asc {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZ0lEQVQ4y2NgGLKgquEuFxBPAGI2ahhWCsS/gDibUoO0gPgxEP8H4ttArEyuQYxAPBdqEAxPBImTY5gjEL9DM+wTENuQahAvEO9DMwiGdwAxOymGJQLxTyD+jgWDxCMZRsEoGAVoAADeemwtPcZI2wAAAABJRU5ErkJggg==);
}

.desc {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZUlEQVQ4y2NgGAWjYBSggaqGu5FA/BOIv2PBIPFEUgxjB+IdQPwfC94HxLykus4GiD+hGfQOiB3J8SojEE9EM2wuSJzcsFMG4ttQgx4DsRalkZENxL+AuJQaMcsGxBOAmGvopk8AVz1sLZgg0bsAAAAASUVORK5CYII=);
}

.loading-mask {
  position: absolute;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.loading-content {
  display: table-cell;
  vertical-align: middle;
}

.loading-icon {
  display: block;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  /* background-color: #fff; */
  background-color: var(--widgetBg);
  background-clip: border-box;
  /* padding: 20px; */
}

.darkCard {
  background: var(--navBar2Bg);
  transition: var(--transition);
}

select {
  width: auto;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  height: auto;
  padding: 0px;
  margin-bottom: 0px;
}

table {
  table-layout: fixed;
  width: 100%;
  color: #212529;
  /* border-collapse: collapse; */
  border-collapse: separate;
  border-spacing: 0px 0;
}

th {
  text-align: inherit;
}

tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}

.table td,
.table th {
  /* padding: 0.75rem; */
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table-border {
  border: 1px solid #dee2e6;
}

.table td,
.table th {
  vertical-align: middle;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}

.table td,
.table th {
  /* padding: 0.75rem; */
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

/* td{
  display: inline-table;
} */
.overflow-auto {
  background: #727174;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  /* margin-right: -15px;
  margin-left: -15px; */
}

div.dataTables_wrapper div.dataTables_paginate {
  white-space: nowrap;
  text-align: right;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin: 2px 0;
  white-space: nowrap;
  justify-content: center;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

*,
::after,
::before {
  box-sizing: border-box;
}

@media (min-width: 576px) {
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

select {
  width: 100%;
  box-shadow: 0 10px 25px rgb(0 0 0 / 50%);
  font-size: 8px;
  padding: 0 1.5em;
  background: #0e1016;
  color: white;
  /* appearance: none; */
  border: 0;
}

.custom-select-table {
  position: relative;
  font-size: calc(0.6em + 0.148vmin + 0.148vmax) !important;
}

.custom-arrow {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  background: red;
  height: 100%;
  width: 4rem;
  pointer-events: none;
}

.custom-arrow::before,
.custom-arrow::after {
  --size: 1em;
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.custom-arrow::before {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-bottom: var(--size) solid red;
  top: 35%;
}

.custom-arrow::after {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-top: var(--size) solid red;
  top: 65%;
}

.auto-font {
  font-size: calc(0.6em + 0.148vmin + 0.148vmax) !important;
}

.nodata-msg {
  font-size: 14px;
}

.highlight-row {
  background-color: var(--activeTextColor) !important;
  /* color: var(--selectingTimeFeetColor) !important;
  background-color: rgb(126, 206, 206) !important; */
  color: white !important;
  font-weight: bold;
  border: 1px solid gray
}

.input-black {
  background-color: black;
}

.highlight-on-input-focus:focus-within {
  background-color: black;
  border-radius: 8px;
  color: white !important;
  /* Change this color to the desired background color */
}

.focused {
  background-color: black;
  color: white !important;
  /* Change this color to the desired background color */
}

.dropdown {
  outline: none;
  /* padding: 5px 8px; */

  font-weight: bold;
  background-color: inherit;
  color: var(--textColor);

  box-shadow: none;
  /* border-bottom: 1px solid var(--activeTextColor); */


}

.dropdown-icon {
  color: var(--textColor);
  /* border-bottom: 1px solid var(--activeTextColor); */

}

.dropdown-div {
  border-bottom: 1px solid var(--activeTextColor);
}

.option {

  font-weight: bolder;
  background-color: var(--navBar2Bg);
  color: var(--textColor)
}

.action-container {
 
  display: flex;
  flex-direction: column;
  margin-bottom: 10px !important;

}
</style>
