<template>
  <div class="">
    <nav id="header" class="w-full">
      <div class="flex break-words bg_dark_gray newSty_bg w-full">
        <div class="flex w-full">
          <div class="flex justify-between w-full py-0.5" name="expand">
            <a
              href="#"
              class="hamburger_icon cursor-pointer z-6 ml-2"
              @click="toggleLeftSideBar()"
            >
              <svg
                class="hamburgerTandD"
                xmlns="http://www.w3.org/2000/svg"
                :class="{ ActiveHamburgerTandD: show_left_bar }"
                width="20"
                height="15"
                viewBox="0 0 20 15"
                fill="none"
              >
                <path
                  d="M0 6.25H15V8.75H0V6.25ZM0 0H20V2.5H0V0ZM0 15H9.04375V12.5H0V15Z"
                  :fill="darkDark != 'white' ? '#fff' : '#000'"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>
  <script>
export default {
  props: {
    onToggleSideBar: Function,
    show_left_bar: Boolean,
    displayId: String,
    widgetId: String,
  },
  data() {
    return {
      isHamburgerClickedTandD: false,
    };
  },
  computed: {
    darkDark() {
      return this.$store.state.rect.darkmode;
    },
  },
  methods: {
    toggleLeftSideBar() {
      this.onToggleSideBar();
      this.isHamburgerClickedTandD = !this.isHamburgerClickedTandD;
    },
  },
  mounted() {},
  watch: {},
};
</script>
  <style scoped>
.hamburgerTandD path {
  /* fill: var(--defalutHamburger); */
  fill: var(--textColor);
}

.ActiveHamburgerTandD path {
  /* fill: var(--activeHamburger); */
  fill: var(--activeTextColor);
}
.buttonStyles {
  border: 1px solid var(--activeTextColor);
  /* font-size: 10px; */
  color: var(--activeTextColor);
  border-radius: 3px;
  padding: 1px 5px;
  text-transform: uppercase;
}
.fontSize {
  font-size: calc(0.2vw + 0.2vh + 0.65vmin);
}
.newSty_bg {
  background: var(--central_bg) !important;
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.15) !important;
  height: 30px;
}
</style>
  